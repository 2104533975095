
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import Building from "@/components/reusable/policy-information/house-insurance/OwnedBuilding.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import { useStore } from "vuex";
import "viewerjs/dist/viewer.css";

export default defineComponent({
  name: "owned-building-house-insurance-policy-details",
  components: { GoBackButton, Building },
  props: ["publicId"],
  data() {
    return {
      policyDetailsSummary: {},
      route: "",
      userRole: "",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Owned Building", [
        "Policy",
        "Policy Information",
        "House Insurance"
      ]);
    });
  },
  created() {
    const router = useRouter();
    const store = useStore();
    const userType = store.getters.currentUser;
    this.userRole = userType.role;

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Policy information route based on user roles
    if (userType.role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
      this.route = variables.POLICIES_ROUTE + "/folio-item/" + this.publicId;
    } else if (userType.role == variables.BROKER_USER_ROLE) {
      // Broker role policy information route
      this.route = variables.BROKER_CLIENT_MOTOR_SCHEDULE_ROUTE + this.publicId;
    } else if (
      userType.role == variables.ADMIN_USER_ROLE ||
      userType.role == variables.SUPER_ADMIN_USER_ROLE
    ) {
      // Super Admin & Admin role policy information route
      this.route =
        variables.SUPERADMIN_CLIENT_MOTOR_SCHEDULE_ROUTE + this.publicId;
    }

    // Get the details of the vehicle in schdule list
    this.getOwnedBuildingDetails();

    //Set page title
    document.title =
      "Owned Building House Insurance Policy Details | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    getOwnedBuildingDetails() {
      const router = useRouter();
      //Get cart policyDetailsSummary details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(this.route)
          .then(({ data }) => {
            //Assign data to corresponding fields
            this.policyDetailsSummary = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
            router.push({ name: "client-policy" });
          });
      }
    }
  }
});
