
import { defineComponent, ref, watch } from "vue";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import ContentTable from "@/components/reusable/policy-information/house-insurance/ContentTable.vue";
import Generators from "@/components/reusable/policy-information/house-insurance/Generators.vue";
import "viewerjs/dist/viewer.css";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "house-building-policy-information-component",
  components: { ContentTable, Generators },
  props: {
    widgetClasses: String,
    policySummary: Object,
    userRole: String,
  },
  data() {
    return {
      imageURL: "",
    };
  },
  setup(props) {
    const {
      isEmptyArray,
      isObjectEmpty,
      isObjectTrulyEmpty,
    } = ResusableFunctions();
    const isOwnedBuilding = ref(true);
    const isRentedBuilding = ref(false);

    const updateBuildingStatus = () => {
      if (props?.policySummary?.building) {
        const reinstatementValue =
          props.policySummary.building.buildingReinstatementValue;

        isOwnedBuilding.value = reinstatementValue !== 0;
        isRentedBuilding.value = reinstatementValue === 0;
      } else {
        isOwnedBuilding.value = false;
        isRentedBuilding.value = false;
      }
    };

    const downloadImage = (imageURL, publicId) => {
      const link = document.createElement("a");
      link.href = `${imageURL}${publicId}`;
      link.download = publicId;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    watch(() => props.policySummary?.building, updateBuildingStatus, {
      immediate: true,
    });

    return {
      isEmptyArray,
      isObjectEmpty,
      isObjectTrulyEmpty,
      isOwnedBuilding,
      isRentedBuilding,
      downloadImage,
    };
  },
  created() {
    // Policy images route
    this.imageURL = variables.POLICY_IMAGES_ROUTE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    daysDifference(endDate) {
      const startDate = variables.currentDate();
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      return variables.daysDifference(startDateObj, endDateObj);
    },
    downloadEndorsement(publicId, registrationNumber) {
      const submitButton1 = document.getElementById(publicId);

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.setResponseType("blob");
          ApiService.get(
            `${variables.POLICIES_ROUTE}/${publicId}/cover-document`
          )
            .then((response) => {
              submitButton1.removeAttribute("data-kt-indicator");
              const pdfFormat = "application/pdf";

              const mimeType =
                response.data.type == pdfFormat ? pdfFormat : "image/jpeg";
              const mimeTypeExtension =
                response.data.type == pdfFormat ? "pdf" : "jpeg";

              const blob = new Blob([response.data], {
                type: mimeType,
              });

              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = `${registrationNumber}-endorsement.${mimeTypeExtension}`;

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(function(error) {
              console.log(error);
              variables.toastAlert(error.response.data.error, "error");
              submitButton1.removeAttribute("data-kt-indicator");
            });
        }
      }
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
    printPage(el) {
      variables.printPage(el);
    },
  },
});
